@use "../abstract" as *;

.buynowactivebutton {
  padding: 10px;
  // background: linear-gradient(100%, #9c1303 50%, #e81402 50%);
  color: white;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(98deg, #9c1303 0%, #e81402 100%);

  width: 100%;
  border: none !important;
}

.activenowactivebutton {
  border: 1px solid #263468;
  padding: 10px;
  // background: linear-gradient(100%, #9c1303 50%, #e81402 50%);
  color: white;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  color: #263468;
  width: 100%;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}
.buynotactive {
  border: 1px solid #263468;
  padding: 10px 40px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  color: #263468;
  padding: 10px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;

  @include mdBreakPoint {
    padding: 5px;
  }
}

.addtocardbutton {
  border: 1px solid #263468;
  color: #263468;
  font-weight: 600;
  cursor: pointer;
}

// active buttons
.main-card-active {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include xsBreakPoint {
    gap: 10px;
  }
}
.activecardbutton {
  background-color: #f3faff;
  border: 1px solid #263438;
  font-weight: 600;

  border-radius: 15px;
  cursor: pointer;
  padding: 5px 30px;
}

.inactivecardbutton {
  background-color: white;
  color: black;
  font-weight: 400;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  cursor: pointer;
  padding: 5px 30px;
  border-radius: 15px;
}

// active buttons

.active-path-border {
  width: 100%;
  display: flex;
  // border: 1px solid red;
  border-left: 4px solid #9c1303;
  background-color: #fad0cc;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  color: #9c1303;
  padding: 10px 10px;
}

.inside-sidebar-iconss {
  width: 100%;
  display: flex;
  // border: 1px solid red;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
}

.common-centers {
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 3px solid blue;
}

.buynowbtn {
  border: 1px solid #263468;
  border-radius: 8px;
  color: #263468;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  height: 30px;
  width: 100px;
  //   margin: 30px auto;
}

// login button

.loginButton {
  background: linear-gradient(98deg, #9c1303 0%, #e81402 100%);
  color: white;
  cursor: pointer;
  width: 100% !important;
  padding: 9px 0px !important;
  outline: none !important;
  border: none !important;
  border-radius: 10px;
}

.take-test {
  background: linear-gradient(98deg, #9c1303 0%, #e81402 100%);
  color: white !important;
  cursor: pointer;
  width: 40% !important;
  padding: 9px 0px !important;
  outline: none !important;
  border: none !important;
  border-radius: 10px;
  @include xsBreakPoint {
    width: 200px !important;
    margin-bottom: 15%;
  }
  @include smBreakPoint {
    width: 55% !important;
  }
  @include mdBreakPoint {
    width: 49% !important;
  }
  @include lgBreakPoint {
    width: 49% !important;
  }
  @include xlBreakPoint {
    width: 49% !important;
  }
}

.retake-test {
  // background: linear-gradient(98deg, #9c1303 0%, #e81402 100%);
  color: #263468;
  cursor: pointer;
  width: 40% !important;
  padding: 9px 0px !important;
  outline: none !important;
  // border: none !important;
  border-radius: 10px;
  border: 1px solid #263426;
  @include xsBreakPoint {
    width: 200px !important;
    margin-bottom: 15%;
  }
  @include smBreakPoint {
    width: 55% !important;
  }
  @include mdBreakPoint {
    width: 49% !important;
  }
  @include lgBreakPoint {
    width: 49% !important;
  }
  @include xlBreakPoint {
    width: 49% !important;
  }
}
.loginButton-new {
  background: linear-gradient(98deg, #9c1303 0%, #e81402 100%);
  color: white;
  cursor: pointer;
  width: 100% !important;
  padding: 9px 0px !important;
  outline: none !important;
  border: none !important;
  border-radius: 10px;
}
.cancelButton {
  color: #e81402 !important;
  cursor: pointer;
  width: 100% !important;
  padding: 9px 0px !important;
  outline: none !important;
  border: none !important;
  border-radius: 10px;
  background-color: white !important;
  border: 1px solid #e81402 !important;
}

.loginButtonss {
  color: #263468 !important;
  border: 1px solid #263468 !important;
  cursor: pointer;
  width: 100% !important;
  padding: 9px 0px !important;
  outline: none !important;
  // border: none !important;
  background-color: white !important;
}

// password section

.formsectioncommonlogin {
  position: relative;
  // border:2px solid blue;
  .passwordicons {
    position: absolute;
    right: 2%;
    bottom: 16%;

    cursor: pointer;
  }
}

// signup button tabs

.activetabsignup {
  background-color: #f3faff;
  border: 1px solid #263468;
  font-weight: 400;
  border-radius: 20px;
  cursor: pointer;
  padding: 7px 20px;
  color: #263468;
  @include xsBreakPoint {
    // width: 85vw !important;
    padding: 7px 15px;
    font-size: 16px !important;
  }
  @include mdBreakPoint {
    // width: 85vw !important;
    padding: 7px 20px;
    font-size: 16px !important;
  }
}

.inactivetabsignup {
  background-color: white;
  color: black;
  font-weight: 400;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  cursor: pointer;
  padding: 7px 20px;
  @include xsBreakPoint {
    // width: vw !important;
    // text-align: center;
    padding: 7px 15px;
    font-size: 16px !important;
  }
  @include mdBreakPoint {
    // width: 85vw !important;
    padding: 7px 20px;
    font-size: 16px !important;
  }
}

.refer-button-friends {
  border: none;

  width: 100%;
  padding: 6px 30px;
  cursor: pointer;
  text-align: center;
  color: white;
  border-radius: 8px;
  background-color: #263468;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lgBreakPoint {
    font-size: 0.984rem;
    width: 100%;
    padding: 6px 11px;
  }
  @include xlBreakPoint {
    font-size: 0.984rem;
    width: 100%;
    padding: 6px 22px;
  }
}
