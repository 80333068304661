@use "../abstract" as *;

//card in course

//card active

.cardactive {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  height: auto;
  width: 25%;
  margin-bottom: 40px;

  cursor: pointer;
  @include xsBreakPoint {
    width: 40%;
  }
  @include mdBreakPoint {
    height: 59vh;
  }
  @include lgBreakPoint {
    width: 40%;
  }
  @include xlBreakPoint {
    width: 30%;
  }
}

//buy course

.buycardactive {
  border: 1px solid #263468;

  border-radius: 8px;
  margin: 10px auto;
  padding: 20px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.buycardinactive {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  margin: 10px auto;
  padding: 20px 20px;
  background-color: #f2f2f2;
  cursor: pointer;
}
.suggestcard {
  display: flex;
  margin: 0 auto;
  border-radius: 8px;
  margin-top: 10px;
  gap: 20px;
  width: 100%;
  border: 1px solid grey;
}

.cardactives {
  width: 24.5% !important;
  border: 1px solid #e5e5e5;
  padding: 0 !important;
  border-radius: 10px;
  height: auto;
  @include xsBreakPoint {
    width: 100% !important;
  }
  @include smBreakPoint {
    width: 49.6% !important;
  }
}

//pagination

.pagination-data {
  padding: 0;
  margin: 0;
}
.pagination-data li {
  list-style: none;
  border: 2px solid #adb5bd;
  border-radius: 10px;
}
.table-filter-info {
  padding: 15px;
}
.thead-primary tr th {
  color: #fff;
}

.rc-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-total-text {
  margin-right: 12px;
  cursor: initial;
}

.rc-pagination-jump-next,
.rc-pagination-jump-prev,
.rc-pagination-next,
.rc-pagination-prev {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
.rc-pagination-jump-next button,
.rc-pagination-jump-prev button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}
.rc-pagination-jump-next button:after,
.rc-pagination-jump-prev button:after {
  display: block;
  content: "•••";
}
.rc-pagination-item,
.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-total-text {
  min-width: initial;
  height: auto;
  line-height: initial;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.rc-pagination-item a,
.rc-pagination-item button,
.rc-pagination-prev a,
.rc-pagination-prev button,
.rc-pagination-next a,
.rc-pagination-next button,
.rc-pagination-total-text a,
.rc-pagination-total-text button {
  padding: 6px 8px;
  height: auto;
  min-width: 32px;
  min-height: 32px;
  border-radius: 8px;
  border: 1px solid transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #656f84 !important;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
}
.rc-pagination-item.rc-pagination-item-active a,
.rc-pagination-item.rc-pagination-item-active a:hover,
.rc-pagination-prev.rc-pagination-item-active a,
.rc-pagination-prev.rc-pagination-item-active a:hover,
.rc-pagination-next.rc-pagination-item-active a,
.rc-pagination-next.rc-pagination-item-active a:hover,
.rc-pagination-total-text.rc-pagination-item-active a,
.rc-pagination-total-text.rc-pagination-item-active a:hover {
  color: #e95a5a !important;
}
.rc-pagination-item a:hover,
.rc-pagination-item button:hover,
.rc-pagination-prev a:hover,
.rc-pagination-prev button:hover,
.rc-pagination-next a:hover,
.rc-pagination-next button:hover,
.rc-pagination-total-text a:hover,
.rc-pagination-total-text button:hover {
  color: white;
}

.arrows {
  width: 12px;
}

.view-ticket-fullcard {
  height: 60vh !important;
  overflow: auto;
  padding-bottom: 1% !important;

  .view-ticket-card {
    background-color: #f5f5f5 !important;
    padding: 1.5% !important;
    margin-bottom: 3vh !important;
    border: 1px solid #d1d1d1;
  }

  .view-ticket-cards {
    background-color: rgba(255, 75, 75, 0.25) !important;
    padding: 1.5% !important;
    margin-bottom: 3vh !important;
    border: 1px solid rgba(233, 90, 90, 0.7);
  }
}

.text-bold {
  font-weight: bold;
}

.no-image-box {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
